/* Global styles */
body {
  font-family: 'Montserrat', sans-serif;
  background-color: hsl(0, 0%, 22%);
  color: #fff;
  margin: 0; /* Ensure no margin around the body */
  overflow-x: hidden; /* Prevent horizontal scrolling on body */
}

/* Navbar Styles */
.navbar .nav-link {
  color: #f7f7f7;
}
.navbar-nav .nav-link.dropdown-toggle {
  font-family: 'Montserrat', sans-serif;  
  font-size: 18px;                    
  font-weight: 600;                   
  color: #ffffff;                        
  transition: color 0.3s ease;        
}

.navbar-nav .nav-link.dropdown-toggle:hover {
  color: #ff6000;                    
  font-weight: 600;                   
}

.navbar .nav-link:hover {
  color: #ff6000;
}

.navbar-nav .dropdown-menu {
  background-color: #f9fafc;          
  padding: 10px 15px;                 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
  display: none;                      
  opacity: 0;                         
  transform: translateY(-10px);       
  transition: all 0.3s ease;          
}

.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translateY(0);           
}

.navbar-nav .dropdown-menu .dropdown-item {
  font-family: 'Montserrat', sans-serif;  
  font-size: 16px;
  color: #000000;                        
  padding: 8px 12px;
  display: flex;                      
  align-items: center;                
  transition: background-color 0.3s ease; 
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
  color: #ff6000;
}

.navbar-nav .dropdown-menu .dropdown-item img {
  width: 20px;                        
  margin-right: 10px;
}

/* Main Content Full Screen */
main {
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Search Section Styles */
.search-section {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 8px;
  background-color: #444;
  margin: 0 auto;
}

/* Company Logo */
.company-logo {
  height: 50px;
}

.dropdown-item img {
  margin-right: 8px;
}

/* Flexbox to Align Results and Results2 Horizontally */
.results-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Table Styles */
.table {
  width: 48%; /* Each table takes up 48% of the width */
  background-color: #444;
  font-size: small;
}

.table-container {
  overflow-x: auto; /* Add horizontal scroll if the table overflows */
  white-space: nowrap;
}

.table th, .table td {
  color: #fff;
  text-align: center;
  align-items: center;
}

/* Total Record Count Styling */
.total-record {
  color: #ff6000;
  font-weight: bold;
}

.div_padding {
  padding-top: 20px;
  padding-bottom: 1px;
}

.text-sitetheme {
  color: #ff6000;
}

/* Scrollbar for Table */
.table-container::-webkit-scrollbar {
  height: 6px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #333;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ff6000;
}

/* Scrollable Content for Tables */
.table-container {
  max-width: 100%;
  overflow-x: scroll;
}

.custom-pagination .page-link {
  background-color: #ff6000; /* Default background color */
  color: white; /* Text color */
  border: none; /* Remove borders */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.custom-pagination .page-link:hover {
  background-color: #f7bd72; /* Hover background color */
  color: white; /* Text color on hover */
}

.custom-pagination .page-item.active .page-link {
  background-color: #d95b00; /* Active page background color */
  color: white; /* Active page text color */
}

.btn-growfox {
  color: #070707 !important; /* Default text color */
  background-color: #ff6000 !important;;  /* Default background */
}

.btn-growfox:hover {
  color: #ffffff; /* Change text color on hover */
  background-color: #ff62008a; /* Change background color on hover */
}

.nav-tabs .nav-link {
  background-color: #f8f9fa; /* Default background for tabs */
  color: #f8f9fa; /* Text color */
  border: 1px solid #dee2e6; /* Border for the tabs */
}

.nav-tabs .nav-link:hover {
  background-color: #e9ecef; /* Hover background */
  color: #ff6000 !important; /* Active tab text color */

}

.nav-tabs .nav-link.active {
  background-color: #ffffff; /* Active tab background color */
  color: #ff6000 !important; /* Active tab text color */
}

.tab-content {
  padding: 15px; /* Adds padding inside the tab content */
  background-color: #ffffff transparent; /* Background for tab content */
  border: 1px solid #dee2e6; /* Border around tab content */
}
